.resultWeek {
    width: 1320px;
    height: 1377px;
    background-color: #FFF;
    border-radius: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
}

.resultWeekTitle {
    font-size: 20px;
    font-weight: 700;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 1196px;
    margin-top: 50px;
}

.resultChartBox {
    width: 1196px;
    height: 300px;
    display: flex;
    justify-content: space-between;
}

.resultBarLine {
    width: 550px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
    position: relative;
}

.resultLine {
    width: 550px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
    position: relative;
}

.barLineBorder {
    position: absolute;
    width: 83px;
    height: 230px;
    border: 3px dashed #00A0A0;
    border-radius: 10px;
    background-color: rgba(0, 160, 160, 0.15);
    pointer-events: none;
    margin-right: 30px;
    margin-top: 39px;
}

.lineBorder {
    position: absolute;
    width: 97px;
    height: 235px;
    border: 3px dashed #00A0A0;
    border-radius: 10px;
    background-color: rgba(0, 160, 160, 0.15);
    pointer-events: none;
    // margin-right: 54px;
    margin-top: 38px;
}