.predOption {
    width: 1320px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
}

.predTitle {
    font-size: 20px;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}

.predText {
    font-size: 13px;
    font-weight: 800;
    margin-bottom: 10px;
    margin-left: 10px;
}

.predBox {
    width: 100%;
    height: 134px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    gap: 40px;
    & svg {
        margin: 0px 20px 0px 20px;
    }
}

.predContent {
    width: 317px;
    height: 76px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    & div {
        width: 100%;
        height: 35px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        font-size: 15px;
        font-weight: 800;
    }
    & p {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 800;
        & pre {
            font-size: 14px;
            font-weight: 800;
        }
    }
    & input {
        width: 100%;
        height: 35px;
        text-align: right;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        padding-right: 10px;
        font-size: 15px;
        font-weight: 800;
        &::placeholder {
            font-size: 15px;
            font-weight: 800;
            color: rgba(0,0,0,0.4);
        }
    }
}

.predBtnBox {
    width: 348px;
    height: 77px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 30px;
    & div {
        width: 100%;
        height: 35px;
        display: flex;
        gap: 20px;
        & p {
            width: 108px;
            height: 100%;
            border: 2px solid rgba(0,0,0,0.2);
            border-radius: 5px;
            font-size: 15px;
            font-weight: 800;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & pre {
            width: 220px;
            height: 35px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 5px;
            font-size: 15px;
            font-weight: 800;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 10px;
        }
    }
    & button {
        width: 100%;
        height: 35px;
        border: none;
        border-radius: 5px;
        background-color: #00A0A0;
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
        color: #FFF;
        font-size: 20px;
        font-weight: 800;
        text-align: center;
    }
}

.positive {
    border: 2px solid rgba(0, 72, 255, 0.2) !important;
    color: #0048FF;
}

.negative {
    border: 2px solid rgba(255, 0, 0, 0.2) !important;
    color: #FF0000;
}

.errorMessage {
    color: #FF0000;
    font-size: 12px !important;
    font-weight: 600;
    position: absolute;
    margin: 70px 0px 0px 5px;
}