.adPerformance {
    width: 1320px;
    height: 470px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.chartBox {
    width: 1032px;
    height: 100%;
    background-color: #FFF;
    border-radius: 10px;
    padding: 7px 15px 7px 15px;
    position: relative;
    & p {
        font-size: 20px;
        font-weight: 800;
        margin:20px 0px 0px 25px;
    }
}

.infoBox {
    width: 1000px !important;
    position: absolute !important;
    & div {
        width: 100% !important;
    }
}

.labelBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    padding-right: 25px;
}

.chart {
    width: 970px;
    height: 380px;
    margin-left: 15px;
}

.avgPerformanceBox {
    width: 246px;
    height: 100%;
    background: linear-gradient(to bottom, #FFF 13%, #8EE3E3 66%, #51C6C6 78%, #00A0A0 95%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    & p {
        font-size: 20px;
        font-weight: 800;
        margin-top: 150px;
    }
    & pre {
        width: 167px;
        height: 60px;
        font-size: 36px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.positive {
    border-bottom: 2px solid #0048FF;
    color: #0048FF;
}

.negative {
    border-bottom: 2px solid #FF0000;
    color: #FF0000;
}