.performanceTable {
    width: 1320px;
    height: 514px;
    background-color: #FFF;
    border-radius: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    & p {
        font-size: 20px;
        font-weight: 800;
        margin-left: 10px;
    }
}

.tableTitle {
    width: 1260px;
    margin-top: 30px;
}

.predTable {
    width: 1223px;
    height: 150px;
    margin-top: 20px;
}

.prevTable {
    width: 1223px;
    height: 179px;
}